import { MeshGradientRenderer } from '@johnn-e/react-mesh-gradient'
import { useEffect, useRef, useState } from 'react'
import { BrowserView, MobileOnlyView } from 'react-device-detect'
import { AiOutlineClose } from 'react-icons/ai'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import './App.css'
import logo from './Logo-transparent-light.png'
import lighthouse from './bitmap.png'
import { WS_URL } from './url'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Id } from 'react-toastify/dist/types'

if (process.env.NODE_ENV === 'production') console.log = console.warn = console.error = () => {}

var APIKey = 'ZcUA1N49ww'

if (process.env.NODE_ENV === 'production') APIKey = 'ZcUA1N49ww'

const validateEmail = (email: string | undefined) => {
  var errors: { email: string | undefined } = { email: undefined }
  if (!email || email === '') return undefined

  if (!email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email)) {
    errors.email = 'Invalid email address'
  }

  return errors.email
}

function App() {
  const [startTime, setStartTime] = useState(Date.now())
  const connToastID = useRef<Id | null>(null)
  const [modalSelection, setModalSelection] = useState<undefined | 'newsletter' | 'message'>(undefined)
  const [userEmail, setUserEmail] = useState('')
  const [userEmailError, setUserEmailError] = useState<undefined | string>()

  const openModal = (sel: undefined | 'newsletter' | 'message') => setModalSelection(sel)
  const closeModal = () => setModalSelection(undefined)

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(WS_URL, {
    share: false,
    shouldReconnect: () => true,
    protocols: [APIKey],
  })

  useEffect(() => {
    if (modalSelection) {
      setUserEmailError(undefined)
    }
  }, [modalSelection])

  // Run when the connection state (readyState) changes
  useEffect(() => {
    if (Date.now() - startTime > 3000 || readyState == ReadyState.OPEN) {
      switch (readyState) {
        case ReadyState.CONNECTING:
          console.log('Connecting to api')
          break
        case ReadyState.CLOSED:
          console.log('Connection Closed')
          if (!connToastID.current) connToastID.current = toast.error('No Connection')

          break
        case ReadyState.OPEN:
          console.log('Connection Open')
          if (connToastID.current) toast.dismiss(connToastID.current)
          break
        case ReadyState.UNINSTANTIATED:
          console.log('connection Uninstantiated')
          break
      }
    }
  }, [readyState])

  // Run when a new WebSocket message is received (lastJsonMessage)
  useEffect(() => {
    if (lastMessage?.data) {
      if (lastMessage.data.includes('reply-subscribe-newsletter')) {
        toast.success('Thank you for subscribing. You will now receive updates and information to your inbox.')
      }
      console.log(`Got a new message: ${lastMessage?.data}`)
    }
  }, [lastMessage])

  const handleNewsletterSubscribeDone = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleNewslSubscribe(event)
      event.currentTarget.blur()
    }
  }

  function handleNewslSubscribe(e: any) {
    e.preventDefault()
    const validationError = validateEmail(userEmail)
    if (!validationError) {
      console.log('Subscribing: ', userEmail)
      return sendJsonMessage({
        event: 'subscribe-newsletter',
        data: {
          email: userEmail,
        },
      })
    } else {
      console.log(validationError)
    }
    setUserEmailError(validationError)
    e.currentTarget.blur()
  }

  return (
    <div className="App">
      <div style={{ width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
        <div
          onClick={() => {
            window.location.reload()
          }}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: 5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5,
            zIndex: 50,
          }}
        >
          <img alt="logo" src={logo} id="logo" className={modalSelection ? 'blurred' : ''} />
          <h3 className={modalSelection ? 'blurred' : ''}>Astor</h3>
        </div>
        <div
          className={modalSelection ? 'blurred' : ''}
          style={{
            paddingBottom: '10vh',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 50,
          }}
        >
          <h1 className="fade3">Navigating the Digital Ocean</h1>
          <h2 className="fade5">
            We live in a unique time, where the vast, ever-expanding ocean of technology surrounds every aspect of our lives. Compass in hand, we
            chart a course for success in the ever-changing digital landscape.
          </h2>
          <div className="horivert fade7" style={{ gap: 10, paddingTop: '2.5vh' }}>
            <button disabled={readyState !== ReadyState.OPEN} onClick={() => openModal('newsletter')} className="btn">
              Sign up for our newsletter
            </button>
            {
              // <button className="btn">Send us a message</button>
            }
          </div>
        </div>

        <img
          className={modalSelection ? 'blurred fadedelay1' : 'fadedelay1'}
          alt="lighthouse"
          src={lighthouse}
          width={'100%'}
          style={{ zIndex: 10, position: 'absolute', bottom: 0, left: 0 }}
        />
        <div
          className="fadedelay2"
          style={{ width: '100vw', height: '3vh', backgroundColor: 'rgb(61,69,86)', position: 'absolute', bottom: 0, left: 0, zIndex: 5 }}
        />

        {modalSelection && (
          <div
            className="fadeModal"
            style={{
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.5)',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bottom: 0,
              left: 0,
              zIndex: 500,
              display: 'flex',
            }}
          >
            <div onClick={(e) => closeModal()} style={{ width: '100vw', height: '100vh', position: 'absolute', zIndex: 10, overflow: 'hidden' }} />
            <div
              className="modal"
              style={{
                gap: 5,
                //  height: 'auto',
                paddingBottom: '40px',
                paddingLeft: '3%',
                paddingTop: '30px',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '92.5%', marginLeft: 20 }}>
                <img alt="logo" src={logo} className="logosm" />
                <div style={{ flex: 1 }} />
                <AiOutlineClose size={30} onClick={() => closeModal()} style={{ cursor: 'pointer' }} />
              </div>
              <h4>Newsletter</h4>
              <h3 style={{ margin: 0, marginLeft: 20, fontWeight: 'normal', color: 'grey', paddingBottom: 50 }}>Keep up to date with the voyage</h3>

              <div className="bigInputContainer">
                <input
                  spellCheck={false}
                  autoCapitalize={'false'}
                  autoComplete={'false'}
                  autoCorrect={'false'}
                  className="bigInput"
                  placeholder="Email"
                  onChange={(e) => {
                    setUserEmailError(undefined)
                    setUserEmail(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    handleNewsletterSubscribeDone(e)
                  }}
                  style={userEmailError ? { border: '2px solid red' } : {}}
                />

                <BrowserView>
                  <div style={{ marginLeft: '-115px', width: '100px' }}>
                    <button onClick={(e) => handleNewslSubscribe(e)} className="btn">
                      Subscribe
                    </button>
                  </div>
                </BrowserView>
              </div>
              <p style={{ fontSize: 14, margin: 0, padding: 0, marginLeft: '20px', color: 'red' }}>{userEmailError ? '*' + userEmailError : ''}</p>
              <MobileOnlyView>
                <div
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flex: 1,
                    paddingLeft: '20px',
                  }}
                >
                  <button onClick={(e) => handleNewslSubscribe(e)} className="btn">
                    Subscribe
                  </button>
                </div>
              </MobileOnlyView>

              <p style={{ margin: 0, paddingLeft: 20 }}>We won't spam. Unsubscribe at any time</p>
            </div>
          </div>
        )}

        <BrowserView style={{ width: '100vw', height: '100vh', position: 'absolute', bottom: 0, left: 0, zIndex: 0 }}>
          <MeshGradientRenderer
            wireframe={true}
            speed={0.005}
            style={{ width: '100vw', height: '100vh', opacity: 0.5 }}
            className="gradient"
            colors={['#749bc2', '#f6f4eb', '#91c8e4', '#f6f4eb', '#91c8e4']}
          />
        </BrowserView>
        <MobileOnlyView style={{ width: '100vw', height: '100vh', position: 'fixed', zIndex: 0, top: 0, left: 0, overflow: 'hidden' }}>
          <div
            onClick={() => closeModal()}
            className="gradient-background"
            style={{ width: '100vw', height: '100vh', position: 'absolute', zIndex: 1, top: 0, left: 0, overflow: 'hidden' }}
          />
        </MobileOnlyView>
      </div>
      <ToastContainer position="bottom-center" draggable pauseOnHover />
    </div>
  )
}

export default App
