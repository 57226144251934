import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { API_URL } from './url'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((vital) => {
  try {
    fetch(API_URL + 'vitals', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(vital),
    })
  } catch (e) {
    console.error(e)
  }
})
